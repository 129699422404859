import { takeLatest, put, call, select } from "redux-saga/effects";
import { firebaseDatabase, firebaseStorage } from "../../controller/firebase";
import { emptyObject, getAsyncCatalog, emptyCero, getAsyncCollection, sleep, consumeServiceGenerico, emptyJson, crearCities } from "../../controller/utils";
import axios from "axios";
import { reset, change, initialize } from "redux-form";
import { callDinamicDisable, cargarCatalogos, contactoAdd, disabledLote, initModalEspera, loadCatalogSuccess, loadDocumentSuccess, reloadPage, saveBase64, selectDiciplina } from "./actions";
import {
    CREATE_DEPOR_POSTULATION,
    HEADERS_WS,
    LOAD_CATALOG,
    REDUX_CHANGE,
    REQUIRED_COMPANY_DATAFORM,
    REQUIRED_DATOS_EPS,
    REQUIRED_DATOS_RESPONSABLE,
    REQUIRED_INFORMACION_PERSONAL,
    REQUIRED_PADRES_EPS,
    UPDATE_FIELD_ATTRIBUTE,
    EMAIL_RESPUESTA,
    IMAGES_DICIPLINA,
    SELECT_OPORTUNIDAD_URL,
    REQUIRED_DATOS_ACUDIENTE,
    REQUIRED_COMPANY_PEOPLE_FORM,
    INICIALIZE_FORM_FROM_OPORTUNIDAD
} from "./constants";
import { getCatalogosCargados, getDiciplina, getFileBase64 } from "./selectors";
import MyPdfDeportes from "../exportpdf/pdfDeportes";
import { pdf } from '@react-pdf/renderer';

import React from "react";

function* submitDeporte(action) {
}



function ordenar(a, b) {
    try {
        if (a.label > b.label) {
            return 1;
        }
        if (a.label < b.label) {
            return -1;
        }
        return 0;
    } catch (error) {
        throw `ordenar - ${error}`;
    }
}

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

function createCatalogo(action) {
    var res = [];
    action.forEach(element => {
        res.push(element.city);
    });
    res = res.filter(onlyUnique);

    const respuesta = [];
    res.forEach(element => {
        respuesta.push({
            label: element, value: element
        })
    });
    return respuesta;
}

function* buildDynamicOptions(action) {
    try {


        const dynamicOptions = {};
        const departament = yield getAsyncCatalog('DEPARTAMENTO');
        const cities = yield getAsyncCatalog('CITIES');
        const documentType = yield getAsyncCatalog('TIPOSDOC');
        const documentTypeAca = yield getAsyncCatalog('TIPOS_DOC_ACADEMIAS');
        const sexo = yield getAsyncCatalog('SEXO');
        const centros = yield getAsyncCatalog('CENTROS_RECREACIONALES');
        const correo = yield getAsyncCatalog('CORREO_VACACIONES');
        const activarDefault = yield getAsyncCollection("CORREO_VACACIONES");
        const indicativo = yield getAsyncCatalog("INDICATIVO_FIJOS");
        const activateIndicativo = yield getAsyncCollection("INDICATIVO_FIJOS");
        dynamicOptions.centroRecreacional = centros.sort(ordenar);
        dynamicOptions.tipoDocumentoMenor = documentTypeAca.sort(ordenar);
        dynamicOptions.tipoDocumentoRes = documentType.sort(ordenar);
        dynamicOptions.deptoExpedida = departament.sort(ordenar);
        dynamicOptions.sexo = sexo.sort(ordenar);
        dynamicOptions.departamentDepor = departament.sort(ordenar);
        dynamicOptions.cities = cities;

        //dynamicOptions.periodoInscripcion = semanas.sort(ordenar);
        yield put(loadCatalogSuccess(dynamicOptions));
        yield put(cargarCatalogos({ cities, centros, indicativo, activateIndicativo, depto: departament, sexo, correo, activarDefault }));

        return dynamicOptions;
    } catch (error) {
        throw `buildDynamicOptions- ${error}`;
    }

}

function* createCatalog() {

    const datos = [{
        value: "1", label: "Certificación de ahorros previos con fecha de expedición no válida"
    },
    {
        value: "2", label: "Estado de cuenta de cesantías sin inmovilizar"
    },
    {
        value: "3", label: "Certificado de Fiducia con información incompleta"
    },
    {
        value: "4", label: "Certificado cuota inicial de constructora con información incompleta"
    },
    {
        value: "5", label: "Certificado de ingresos con información incompleta o errada"
    },
    {
        value: "6", label: "Certificación de ahorro programado sin inmovilizar, sin firma física ni digital o sello"
    },
    {
        value: "7", label: "Pendiente de levantar inhabilidad"
    },
    {
        value: "8", label: "Documento(s) sin cumplir lineamientos para actualización (clave de acceso, tipo de archivo distinto a PDF, fotografía, escaneado incompleto o ilegible, estado de cuentas, extracto o comprobante de pago, etc.)"
    },
    {
        value: "9", label: "Sin afiliación activa a caja de compensación"
    },
    {
        value: "10", label: "Mora en aportes"
    },
    {
        value: "11", label: "Ingresos superiores a 4 smmlv"
    },
    {
        value: "12", label: "Inconsistencia en la información"
    }]



    // const resul = yield firebaseDatabase.collection(`catalogos/CENTROS_RECREACIONALES/data`).get();
    // let catalogo = []
    // resul.docs.map(doc => {
    //     catalogo.push(doc.data());
    // });
    // console.log(JSON.stringify(catalogo));
    try {
        yield datos.map(item => {
            // item.label = item.label.toUpperCase()
            firebaseDatabase.collection(`catalogos/MOTIVOS_DEVOLUCION/data`).add(item);
        });

        console.log("hecho");
    } catch (e) {
        console.error(e);
    }
}

function* borrarData() {





    const resul = yield firebaseDatabase.collection(`solicitudesVivienda`).where("state", "==", "borrador").get();
    let catalogo = [];
    resul.docs.map(doc => {
        catalogo.push({ data: doc.data(), id: doc.id });
    });
    console.log(catalogo);
    try {
        let newCatalog = []
        catalogo.forEach(e => {
            newCatalog.push({
                data: {
                    ciclos: e.data.ciclos ? e.data.ciclos : [],
                    date: e.data.date,
                    state: e.data.state,
                    step1: e.data.step1 ? e.data.step1 : {},
                    step2: e.data.step2 ? e.data.step2 : {},
                    step3: {},
                    step4: {},
                    step5: e.data.step5 ? e.data.step5 : {},
                    step6: e.data.step6 ? e.data.step6 : {},
                    refFiles: { refStorage: {} },
                    userid: e.data.userid,
                }, id: e.id
            })
        });

        console.log(newCatalog);
        for (let i of newCatalog) {
            yield firebaseDatabase.collection(process.env.REACT_APP_FIREBASE_COLECCION_VIVIENDA).doc(i.id).set(i.data, { merge: true });
        }

        console.log("hecho");
    } catch (e) {
        console.error(e);
    }
}

function calcularEdad(fecha) {
    var hoy = new Date();
    var cumpleanos = new Date(fecha);
    var edad = hoy.getFullYear() - cumpleanos.getFullYear();
    var m = hoy.getMonth() - cumpleanos.getMonth();

    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edad--;
    }

    return edad;
}

function* loadDocumentType(action) {
    try {
        const catalogo = yield select(getCatalogosCargados);
        const ciudades = catalogo.cities;

        const { meta: { field, form }, payload, } = action;
        var aux = field.split(".");
        var state = false;

        if (form === "formDeportesVacaciones" && field === "informacionpersonal.birthdate") {
            const edad = calcularEdad(payload)
            yield put(change("formDeportesVacaciones", "informacionpersonal.age", edad.toString()));
        }

        switch (aux[1]) {
            case "departamentDepor":
                const citiesP = crearCities(ciudades, payload);
                const newData = {
                    disable: false,
                    options: citiesP.sort(ordenar),
                    field: "cityDepor",
                };
                return yield put(loadDocumentSuccess(newData));

            case "deptoExpedida":
                const citiesE = crearCities(ciudades, payload);
                const newDataE = {
                    disable: false,
                    options: citiesE.sort(ordenar),
                    field: "cityExpedida",
                };
                return yield put(loadDocumentSuccess(newDataE));

            case "afiliadoEps":

                if (payload === "2") {
                    state = true;
                }
                return yield put(callDinamicDisable({ field: "entidadEps", state }));;

            case "medicinaPrepagada":
                if (payload === "2") {
                    state = true;
                }
                return yield put(callDinamicDisable({ field: "entidadMep", state }));

            case "enfermedad":
                if (payload === "2") {
                    state = true;
                }
                return yield put(callDinamicDisable({ field: "answerEnfermedad", state }));

            case "medicamento":
                if (payload === "2") {
                    state = true;
                }
                return yield put(callDinamicDisable({ field: "answerMedicamento", state }));

            default:
                return;
        }
    } catch (error) {
        throw `loadDocumentType - ${error}`
    }
}



function* validateForm(action) {
    try {
        var valid = true;
        const catalog = yield select(getCatalogosCargados)
        const { body } = action;
        var banObligatoriedad = true;
        var banIndi = false;
        var messageIndicativo = false;
        if (catalog.activateIndicativo?.ACTIVATE_DEPORTES) {
            banIndi = true;
        }
        if (body) {
            const requiredcompanydataform = REQUIRED_COMPANY_DATAFORM;

            const requiredinformacionpersonal = REQUIRED_INFORMACION_PERSONAL;

            const requireddatosgenerales = REQUIRED_DATOS_EPS;

            const requireddatosresponsable = REQUIRED_DATOS_RESPONSABLE;


            if (!body?.juramentoPartes?.tratamientoCheck) {
                valid = false;
            }

            if (!body?.juramentoPartes?.habeasData) {
                valid = false;
            }

            if (body.companydataform) {
                requiredcompanydataform.forEach((field) => {
                    if (!body.companydataform[field]) {
                        valid = false;
                    }
                });
            } else {
                valid = false;
            }

            if (body.informacionpersonal) {
                requiredinformacionpersonal.forEach((field) => {
                    if (!body.informacionpersonal[field]) {
                        valid = false;
                    }
                });
            } else {
                valid = false;
            }

            if (body.datosgenerales) {
                const cop = REQUIRED_PADRES_EPS;

                requireddatosgenerales.forEach((field) => {
                    if (!body.datosgenerales[field]) {
                        valid = false;
                    } else {
                        if (body.datosgenerales[field] === "1") {
                            cop.forEach(element => {
                                if (element.padre === field) {
                                    if (!body.datosgenerales[element.hijo]) {

                                        valid = false;
                                    }
                                }
                            });

                        }
                    }
                });
            } else {
                valid = false;
            }

            if (body.datosresponsable) {
                requireddatosresponsable.forEach((field) => {
                    if (!body.datosresponsable[field]) {
                        valid = false;
                    }
                });
                if (banIndi && emptyObject(body?.datosresponsable?.phoneRes).length > 0) {
                    const res = validateIndicativo({ number: body?.datosresponsable?.phoneRes, catalogo: catalog.indicativo })
                    if (!res) {
                        valid = false;
                        if (!messageIndicativo) {
                            messageIndicativo = `El indicativo ingresado en el campo "Telefono" es incorrecto `;
                        } else {
                            messageIndicativo = `${messageIndicativo}, el indicativo ingresado en el campo "Telefono" es incorrecto `;
                        }

                    }
                }
            } else {
                valid = false;
            }

        } else {
            valid = false;
        }
        return { valid, banObligatoriedad, messageIndicativo };
    } catch (error) {
        throw `validateForm - ${error}`;
    }
}

function siORno(action) {
    return action === "1" ? "SI" : "NO";
}

function validateIndicativo(action) {
    const { number, catalogo } = action;
    var ban = false;
    if (emptyObject(number).length === 10) {
        const temp = number.substring(0, 3);
        catalogo.forEach(element => {
            if (element.value === temp) {
                ban = true;
                return;
            }
        });
    }
    return ban;
}

function crearLabel(action) {
    const { catalogo, id } = action;
    var value = "";
    catalogo.forEach(element => {
        if (id === element.value) {
            value = element.label;
            return;
        }
    });
    return value;
}

function* createDeporPostulation(action) {
    try {
        //yield createCatalog();
        //yield borrarData();
        const { value: { body } } = action;
        const valid = yield validateForm(action.value);
        const catalog = yield select(getCatalogosCargados);
        if (valid.valid) {
            yield put(reloadPage({ reload: false }));
            yield put(
                contactoAdd({
                    message: "Procesando su petición, permanezca en linea.  ",
                    error: false,
                })
            );
            try {
                var companydataform = body.companydataform;
                var informacionpersonal = body.informacionpersonal;
                var datosgenerales = body.datosgenerales;
                var datosresponsable = body.datosresponsable;
                companydataform.centroRecreacional = crearLabel({ id: emptyObject(companydataform.centroRecreacional), catalogo: catalog.centros });
                informacionpersonal.segundoNombre = emptyObject(informacionpersonal.segundoNombre);
                datosresponsable.phoneRes = emptyObject(datosresponsable.phoneRes);
                datosgenerales.entidadEps = emptyObject(datosgenerales.entidadEps);
                datosgenerales.entidadMep = emptyObject(datosgenerales.entidadMep);
                datosgenerales.answerMedicamento = emptyObject(datosgenerales.answerMedicamento);
                datosgenerales.answerEnfermedad = emptyObject(datosgenerales.answerEnfermedad);
                datosgenerales.dosis = emptyObject(datosgenerales.dosis);
                datosgenerales.afiliadoEps = siORno(datosgenerales.afiliadoEps);
                datosgenerales.medicinaPrepagada = siORno(datosgenerales.medicinaPrepagada);
                datosgenerales.medicamento = siORno(datosgenerales.medicamento);
                datosgenerales.enfermedad = siORno(datosgenerales.enfermedad);
                informacionpersonal.depaExp = crearLabel({ id: emptyObject(informacionpersonal.deptoExpedida), catalogo: catalog.depto });
                informacionpersonal.cityExp = crearLabel({ id: emptyObject(informacionpersonal.cityExpedida), catalogo: catalog.cities });
                informacionpersonal.depaPlace = crearLabel({ id: emptyObject(informacionpersonal.departamentDepor), catalogo: catalog.depto });
                informacionpersonal.cityPlace = crearLabel({ id: emptyObject(informacionpersonal.cityDepor), catalogo: catalog.cities });
                informacionpersonal.sexoNa = crearLabel({ id: emptyObject(informacionpersonal.sexo), catalogo: catalog.sexo });
                const d = new Date();
                const formData = {
                    datos: {
                        companydataform,
                        informacionpersonal,
                        datosgenerales,
                        datosresponsable,
                    },
                    dateTime: d,
                }
                yield pdfgenera({ items: formData });
                yield sleep(2000);
                const refForm = yield firebaseDatabase.collection("deportes").add(formData);
                const resActualiza = yield consumeActualizaOportunidad({ items: formData.datos });
                if (resActualiza !== false) {//refForm.f_?.path?.segments?.length > 0) {
                    yield put(reloadPage({ reload: true }));
                    yield put(
                        contactoAdd({
                            message: `Comfenalco Valle Delagente le informa que su petición no fue registrada. ${resActualiza} ;  
                        Por favor Inténtelo más tarde o escríbenos a servicioalcliente@comfenalcovalle.com.co`,
                            error: true
                        })
                    );
                } else {
                    yield put(reloadPage({ reload: true }));
                    yield put(
                        contactoAdd({ message: "Comfenalco Valle Delagente te informa que su petición fue registrada de manera exitosa.", error: false })
                    );
                }

            } catch (e) {
                console.error("Excetion: " + e.name + ": " + e.message);
                throw `Exception - ${e.message}`;
            }
        } else {

            yield put(reloadPage({ reload: true }));
            yield put(
                contactoAdd({
                    message: !valid.messageIndicativo ? "Debes diligenciar todos los campos marcados con asterisco (*)"
                        : `Debes diligenciar todos los campos correctamente: ${valid.messageIndicativo}`,
                    error: true,
                })
            );
        }
    } catch (error) {
        throw `createDeporPostulacion - ${error}`;
    }

}

function* consumeActualizaOportunidad(action) {

    const { items } = action;

    const messageid = items.informacionpersonal.tipoDocumentoMenor + items.informacionpersonal.idDocumento;
    var date = "";
    var date = new Date();
    const base = yield select(getFileBase64);
    console.log(base)
    var archivo = [];
    if (base) {
        archivo = base;
    } else {
        archivo = null;
    }

    const infoOportu = {
        "idOportunidad": items.companydataform.oportunidad,
        "faseVenta": "Z63",
        "tipoIdentificacion": items.informacionpersonal.tipoDocumentoMenor,
        "numeroIdentificacion": items.informacionpersonal.idDocumento,
        "infoVacaciones": {
            "eps": items.datosgenerales.entidadEps,
            "grupoVacaciones": " ",//items.companydataform.grupo,
            "semana": " ",//items.companydataform.periodoInscripcion,
            "nombreRecoge": items.datosresponsable.nombresRes,
            "celularRecoge": items.datosresponsable.cellPhoneRes,
            "telefonoRecoge": items.datosresponsable.phoneRes ? items.datosresponsable.phoneRes : "",
            "emailRecoge": items.datosresponsable.emailRes
        },
        "archivo": archivo
    }


    const urlConfenalco = process.env.REACT_APP_URL_COMFENALCO_ENDPOINT;
    const token = Buffer.from('FORMULARIOSWEB:Yk2190_w', 'utf8').toString('base64')
    const autho = {};
    const headers = HEADERS_WS;
    const bodyOp = {
        "requestMessageOut": {
            "header": {
                "invokerDateTime": date,
                "moduleId": "OPORTUNIDAD",
                "systemId": "FORMULARIOSWEB",
                "messageId": messageid,
                "logginData": {
                    "sourceSystemId": "",
                    "destinationSystemId": ""
                },
                "destination": {
                    "namespace": "http://co/com/comfenalcovalle/esb/ws/CRMCreaActualizaOportunidad",
                    "name": "CRMCreaActualizaOportunidad",
                    "operation": "execute"
                },
                "securityCredential": {
                    "userName": "",
                    "userToken": ""
                },
                "classification": {
                    "classification": ""
                }
            },
            "body": {
                "request": {
                    "oportunidad": {
                        "accion": "2",
                        "infoOportunidad": infoOportu,
                    }
                }
            }
        }
    }
    var resul = false;
    try {
        console.time("inicio");
        console.log("body actualiza oportunidad deportes", JSON.stringify(bodyOp))
        const data = [];
        data.push(urlConfenalco)
        data.push(headers)
        data.push(bodyOp)
        const ax = (data) => {
            return axios({ method: "post", url: data[0], data: data[2], Headers: data[1] })
        }
        const res = yield call(ax, data);
        console.log("respuesta actualiza oportunidad", res)
        if (res.data.responseMessageOut.header.responseStatus.statusCode === "SUCESS") {
            resul = false;//res.data.responseMessageOut.body.response.idOportunidad;
        } else {
            resul = res.data.responseMessageOut?.body?.exceptionList?.exception?.description ? res.data.responseMessageOut?.body?.exceptionList?.exception?.description : "Time out";
            var cuerpo = bodyOp;
            cuerpo.respuesta = resul;
            yield enviarEmail({ oportunidad: items.companydataform.oportunidad, data: resul });
        }
        console.timeEnd("inicio");
    } catch (error) {
        console.error(error, "error")
        throw error;
    }
    return resul;
}

function* enviarEmail(action) {
    try {
        const url = process.env.REACT_APP_URL_SEND_EMAIL;
        const headers = HEADERS_WS;
        action.to = "<dzarate@comfenalcovalle.com.co>,<ocuichan@comfenalcovalle.com.co>";
        action.subject = "Falla en la actualización de oportunidad de susbsidio Academias deportivas";
        const body = action;
        const respues = yield call(axios.post, url, body, headers);

    } catch (error) {
        console.error("Function.enviarEmail", error);
        throw `enviarEmail - ${error}`;
    }

}

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

function* pdfgenera(action) {
    const { id, items } = action;
    try {
        const resul = yield pdf(<MyPdfDeportes data={items} />).toBlob();
        var date = "";
        var date = new Date().toString().split("GMT");
        if (resul) {
            const res = yield getBase64(resul)
            const type = resul.type;
            const aux = res.split(",")
            const temBase = []

            const temp = {
                contenidoArchivo: aux[1],
                extensionArchivo: type,
                nombreArchivo: `Deportes ${date[0]}`,

            }
            temBase.push(temp);
            yield put(saveBase64(temBase))
        }
    } catch (error) {
        console.error("function.pdfGenera", error)
    }
}


function* selectOportunidadUrl(action) {
    const { value: { oportunidad } } = action;

    if (oportunidad.length > 0 && oportunidad !== false) {
        yield put(initialize("formDeportesVacaciones", { companydataform: { oportunidad: oportunidad.toString() } }));
    }
}

function* initializeOportunidad(action) {
    try {

        const { value: { oportunidad } } = action;
        console.log("action", action);
        yield put(initModalEspera(true));

        const catalog = yield buildDynamicOptions();

        if (!emptyJson(catalog)) {

            const resOportunidad = yield consumeConsultaOportunidad({ oportunidad });
            if (resOportunidad) {
                const customer = resOportunidad.consultaOportunidadResponse.Customer;
                const oportunity = resOportunidad.consultaOportunidadResponse.Opportunity;
                if (oportunity && oportunity.SalesCycle.SalesCyclePhaseCode === "Z15") {

                    var newData = {};
                    const dirCustomer = emptyObject(customer.AddressInformation.Address.PostalAddress.StreetName);
                    const cityNameCustomer = emptyObject(customer.AddressInformation.Address.PostalAddress.CityName).split("-");

                    const cityInfoCustomer = yield catalog.cities.find(c => c.label.includes(cityNameCustomer[0].trim()) && c.label.includes(cityNameCustomer[cityNameCustomer.length - 1].trim()));
                    if (cityInfoCustomer) {
                        console.log(cityInfoCustomer)
                        var cities1 = crearCities(catalog.cities, cityInfoCustomer.id_depa);
                        newData = {
                            disable: false,
                            options: cities1.sort(ordenar),
                            field: "cityDepor",
                        };
                        yield put(loadDocumentSuccess(newData));
                    }


                    let phone = []
                    if (oportunity.ProspectParty.Address?.Telephone?.FormattedNumberDescription) {
                        phone = oportunity.ProspectParty.Address.Telephone.FormattedNumberDescription?.split("+57 ")
                    }
                    else if (oportunity.ProspectParty.Address?.Telephone?.length > 0)
                        phone = oportunity.ProspectParty.Address.Telephone[0].FormattedNumberDescription?.split("+57 ")


                    let items = {
                        companydataform: {
                            oportunidad: oportunidad,
                            centroRecreacional: emptyObject(oportunity.SalesAndServiceBusinessArea?.DistributionChannelCode)
                        },
                        informacionpersonal: {
                            primerNombre: emptyObject(customer.Person.GivenName),
                            segundoNombre: emptyObject(customer.Person.MiddleName),
                            apellidos: emptyObject(customer.Person.FamilyName),
                            tipoDocumentoMenor: emptyObject(customer.TipoIdentificacionCliente),
                            idDocumento: emptyObject(customer.IdentificacionCliente),
                            sexo: emptyObject(customer.Person.GenderCode) === "M" ? "2" : customer.Person.GenderCode === "F" ? "1" : "3",
                            birthdate: emptyObject(customer.CopiaFechanacimiento),
                            age: emptyObject(customer.edad),
                            direccionHome: dirCustomer,
                            departamentDepor: cityInfoCustomer.id_depa,
                            cityDepor: cityInfoCustomer.value,
                        },

                        datosresponsable: {}
                    }

                    if (oportunity.NombreRecoge) {
                        items.datosresponsable = {
                            tipoDocumentoRes: "",
                            idDocumentoRes: "",
                            nombresRes: emptyObject(oportunity.NombreRecoge).replaceAll("-", ""),
                            emailRes: emptyObject(oportunity.CorreoRecoge),
                            cellPhoneRes: emptyObject(oportunity.CelularquienRecoge),
                            phoneRes: emptyObject(oportunity.TelefonoRecoge)
                        }
                    } else {
                        items.datosresponsable = {
                            tipoDocumentoRes: emptyObject(oportunity.TipodeIdentificacion),
                            idDocumentoRes: emptyObject(oportunity.Identificaciondelacudiente),
                            nombresRes: emptyObject(oportunity.Nombredelacudiente).replaceAll("-", ""),
                            emailRes: emptyObject(oportunity.Correodelacudiente),
                            cellPhoneRes: emptyObject(oportunity.Celulardelacudiente),
                            phoneRes: emptyObject(phone[1])
                        }

                    }


                    let lote = [
                        { field: "primerNombre", state: false },
                        { field: "apellidos", state: false },
                        { field: "tipoDocumentoMenor", state: false },
                        { field: "idDocumento", state: false },
                        { field: "sexo", state: false },
                        { field: "birthdate", state: false },
                        { field: "age", state: false },
                        { field: "direccionHome", state: false },
                        { field: "departamentDepor", state: false },
                        { field: "cityDepor", state: false },
                    ];

                    lote.forEach(e => {
                        if (items.informacionpersonal[e.field]) {
                            e.state = true;
                        }
                    });
                    console.log(lote)
                    yield put(disabledLote(lote))
                    yield put(initialize("formDeportesVacaciones", items));
                    //console.log(items)
                } else {
                    const message = oportunity.SalesCycle.SalesCyclePhaseCode === "Z63" ? "La información del formulario correspondiente al número de Oportunidad que intentas registrar ya fue diligenciada y el participante ya está en fase Inscrito. Para mayor información comuníquese con nuestra línea de Servicio al Cliente 602 886 2727 opción 2" : "El número de oportunidad que intentas registrar no se encuentra disponible para el proceso de inscripción, por favor inténtelo nuevamente. En caso de presentarse este mensaje una vez más por favor comuníquese con nuestra línea de Servicio al Cliente 602 8862727 opción 2"
                    yield put(reloadPage({ reload: true }));
                    yield put(
                        contactoAdd({
                            message: message,
                            error: true,
                            refresh: true
                        })
                    );
                }
            } else {
                yield put(reloadPage({ reload: true }));
                yield put(
                    contactoAdd({
                        message: "Ocurrió una respuesta inesperada al momento de validar su información, por favor inténtelo nuevamente. En caso de presentarse este mensaje una vez más por favor comuníquese con nuestra línea de Servicio al Cliente 602 8862727 opción 2",
                        error: true,
                        refresh: true
                    })
                );
            }
        }
        yield put(initModalEspera(false));
    } catch (error) {
        throw ` saja.js - initializeOportunidad - ${error}`;
    }
}

function* consumeConsultaOportunidad(action) {
    /* Función que recibe un número de oportunidad y trae la informacion registrada de este mismo
     */

    const { oportunidad } = action;
    var date = "";
    var date = new Date();

    const urlConfenalco = process.env.REACT_APP_URL_COMFENALCO_ENDPOINT;

    const body = {
        "requestMessageIn": {
            "header": {
                "invokerDateTime": date,
                "moduleId": "CONSULTAOPORTUNIDAD",
                "systemId": "FORMULARIOWEB",
                "messageId": oportunidad,
                "logginData": {},
                "destination": {
                    "namespace": "http://co/com/comfenalcovalle/esb/ws/CRMConsultaOportunidadLanding",
                    "name": "CRMConsultaOportunidadLanding",
                    "operation": "execute"
                },
                "securityCredential": {},
                "classification": {}
            },
            "body": {
                "request": {
                    "consultaOportunidadRequest": {
                        "nroOportunidad": oportunidad
                    }
                }
            }
        }
    }
    var resul = false;
    try {
        const res = yield consumeServiceGenerico({ body, url: urlConfenalco })
        console.log(res.data);
        if (res.data.responseMessageOut.header.responseStatus.statusCode === "SUCESS") {
            resul = res.data.responseMessageOut.body.response;
        }

        return resul;
    } catch (error) {
        console.error(error, "error")
        throw `Function.consumeValidadorSirap - saga.js -${error}`;
    }
}

export function* watchUsers() {
    yield takeLatest(UPDATE_FIELD_ATTRIBUTE, submitDeporte);
    yield takeLatest(CREATE_DEPOR_POSTULATION, createDeporPostulation);
    yield takeLatest(REDUX_CHANGE, loadDocumentType);
    yield takeLatest(SELECT_OPORTUNIDAD_URL, selectOportunidadUrl);
    yield takeLatest(INICIALIZE_FORM_FROM_OPORTUNIDAD, initializeOportunidad);
}
