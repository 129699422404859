import { CLOSE_MODALES, ERROR_GENERAL, INIT_BANDEJA_ADMINISTRADOR, INIT_FORM_FROM_REVALIDACION, LOAD_DOCUMENT_SUCCESS, MESSAGE_ALERT_SEND, MESSAGE_CONFIRMATION_SEND, OCULTAR_DEVOLUCIONES, OPEN_ANEXOS_FROM_TABLE, OPEN_FORM_BANDEJA, OPEN_HISTORIAL_BANDEJA, OPEN_MODAL_ANULATION_TABLE, OPEN_MODAL_CHARGED, OPEN_MODAL_DEVOLUTION_TABLE, SAVE_DATA_TABLE_ANULA_DEVOL, SAVED_INFO_USER_BANDEJA, SET_DATA_PAGINAR_REG_VIVIENDA, TYPE_USER_BANDEJA_VIVIENDA } from "./constants";


export function savedInfoUserBandeja(payload) {
    return {
        type: SAVED_INFO_USER_BANDEJA,
        payload
    };
}

export function setDataPaginarRegVivienda(payload) {
    return {
        type: SET_DATA_PAGINAR_REG_VIVIENDA,
        payload
    };
}

export function errorGeneral(payload) {
    return {
        type: ERROR_GENERAL,
        payload
    };
}

export function messageAlertSend(payload) {
    return {
        type: MESSAGE_ALERT_SEND,
        payload
    };
}

export function saveDataTableAnulaDevol(payload) {
    return {
        type: SAVE_DATA_TABLE_ANULA_DEVOL,
        payload
    };
}

export function openFormBandeja(payload) {
    return {
        type: OPEN_FORM_BANDEJA,
        payload
    };
}

export function openAnexosFromTable(payload) {
    return {
        type: OPEN_ANEXOS_FROM_TABLE,
        payload
    };
}

export function openModalDevolutionTable(payload) {
    return {
        type: OPEN_MODAL_DEVOLUTION_TABLE,
        payload
    };
}

export function openModalAnulationTable(payload) {
    return {
        type: OPEN_MODAL_ANULATION_TABLE,
        payload
    };
}

export function ocultarDevoluciones(payload) {
    return {
        type: OCULTAR_DEVOLUCIONES,
        payload
    };
}

export function initFormFromRevalidacion(payload) {
    return {
        type: INIT_FORM_FROM_REVALIDACION,
        payload
    };
}

export function initBandejaAdministrador(payload) {
    return {
        type: INIT_BANDEJA_ADMINISTRADOR,
        payload
    };
}

export function openModalCharged(payload) {
    return {
        type: OPEN_MODAL_CHARGED,
        payload
    };
}

export function openHistoryBandeja(payload) {
    return {
        type: OPEN_HISTORIAL_BANDEJA,
        payload
    };
}

export function messageConfirmationSend(payload) {
    return {
        type: MESSAGE_CONFIRMATION_SEND,
        payload
    };
}

export function closeModales(payload) {
    return {
        type: CLOSE_MODALES,
        payload
    };
}

export function loadDocumentSuccess(payload) {
    return {
        type: LOAD_DOCUMENT_SUCCESS,
        payload
    };
}

export function typeUserBandejaVivienda(payload) {
    return {
        type: TYPE_USER_BANDEJA_VIVIENDA,
        payload
    };
}