import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from "primereact/tooltip";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

import './style.css';
import { Paginator } from 'primereact/paginator';
import { CSVLink } from "react-csv";


export default class DataTableWithPaginator extends Component {

    constructor(props) {
        super(props);

        this.state = {
            convs: this.props.data,
            globalFilter: null,
            basicFirst: 0,
            basicRows: 10,
            firstLastState: 2,
            paginaAnterior: {},
            listaPaginada: false,
            generandoCsv: false,
            btnDescargarCsv: false,

        };
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.onBasicPageChange = this.onBasicPageChange.bind(this);
        this.generarCSV = this.generarCSV.bind(this);
        this.functionOpenForm = this.functionOpenForm.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    }

    componentDidMount() {
        let datosIniciales = this.props.data;
        this.setState({
            convs: datosIniciales
        })

    }
    componentDidUpdate(prevProps) {
        const { dataPaginada, consultaPaginada, data, totalConvsFiltradas,
            dataToDownloadCsv, nombreConsulta
        } = this.props
        if (this.state.convs != this.props.data) {
            this.setState({
                convs: this.props.data
            });
        }
        const paginatorPrev = document.querySelector('.p-paginator-prev');
        const paginatorNext = document.querySelector('.p-paginator-next');



        if (prevProps.dataToDownloadCsv != dataToDownloadCsv) {
            if (dataToDownloadCsv) {
                this.setState({
                    generandoCsv: false,
                    btnDescargarCsv: true
                });

            }
        }


        if (prevProps.totalConvsFiltradas != totalConvsFiltradas) {
            if (!totalConvsFiltradas) {
                this.setState({
                    listaPaginada: false
                })
            }
        }

        if (dataPaginada) {
            //console.log('entro al iff');
            if (!consultaPaginada) {
                paginatorNext.classList.add('p-disabled');
                paginatorPrev.classList.add('p-disabled');
            } else {
                if (dataPaginada.length < this.state.basicRows) {
                    paginatorNext.classList.add('p-disabled');
                } else {
                    paginatorNext.classList.remove('p-disabled');
                }
                if (this.state.basicFirst == 0) {
                    paginatorPrev.classList.add('p-disabled');
                } else {
                    paginatorPrev.classList.remove('p-disabled');
                }

            }
        } else if (!dataPaginada) {

            /* if (this.state.convs.length < this.state.basicRows) {
                paginatorNext.classList.add('p-disabled');

            } */

            if (!this.state.listaPaginada && (data.length === this.state.basicRows)) {
                paginatorNext.classList.remove('p-disabled');
                paginatorPrev.classList.add('p-disabled');
            } else if (this.state.listaPaginada.length < this.state.basicRows) {
                paginatorNext.classList.add('p-disabled');

            } else if ((this.state.listaPaginada.length === this.state.basicRows) && this.state.basicFirst == 0) {
                paginatorNext.classList.remove('p-disabled');
                paginatorPrev.classList.add('p-disabled');

            }

        }
    }

    paginarConvsFiltradas(event) {
        const { totalConvsFiltradas } = this.props
        console.log('totalConvsFiltradas', totalConvsFiltradas.length);

        let listaPaginada;

        if (event.page !== 0) {
            let page = event.page + 1;
            let last = event.rows * page;
            listaPaginada = totalConvsFiltradas.slice(event.first, last)
        } else {
            listaPaginada = totalConvsFiltradas.slice(event.first, event.rows)
        }
        // console.log("listaFiltradaCortada", listaPaginada);
        this.setState({
            listaPaginada: listaPaginada,
            basicFirst: event.first,
            basicRows: event.rows
        })
    }

    onBasicPageChange(event) {
        const { paginarData, data,
            dataPaginada, totalConvsFiltradas } = this.props

        if (totalConvsFiltradas) {
            this.paginarConvsFiltradas(event)

        } else {
            let ultimaConv = false;
            let primeraConv = false;
            let nextPage = this.state.firstLastState < event.first;


            this.setState({
                firstLastState: event.first,
                listaPaginada: false,
            });

            if (dataPaginada) {

                if (dataPaginada.length === event.rows && event.first > 0) {
                    //console.log('entro a paginar conversacionesPaginadas');
                    ultimaConv = dataPaginada[event.rows - 1];
                    primeraConv = dataPaginada[0];
                } else {
                    //console.log('entro a ultima pagina');
                    ultimaConv = dataPaginada[dataPaginada.length - 1];
                    primeraConv = dataPaginada[0];
                }
            } else if (!dataPaginada && data) {
                if (data.length === event.rows) {
                    //console.log('entro a paginar conversations');
                    ultimaConv = data[event.rows - 1];
                    primeraConv = data[0];
                }
            }
            this.setState({
                basicFirst: event.first,
                basicRows: event.rows
            });

            if (nextPage) {
                paginarData({ event, "ultimaConv": ultimaConv, nextPage });
            } else {
                paginarData({ event, "ultimaConv": primeraConv, nextPage });
            }
        }
    }

    functionOpenForm(e) {
        try {
            this.setState({ selectedFile: e.idFormulario })
            const { searchFormFromTable } = this.props
            searchFormFromTable(e);
        } catch (error) {
            console.error("functionOpenForm", error)
        }

    }


    generarCSV() {
        const { descargarCSV, dataToDownloadCsv } = this.props

        this.setState({
            generandoCsv: true
        })
        descargarCSV({ dataToDownloadCsv })

    }



    rightToolbarTemplate() {
        const { dataToDownloadCsv, nombreConsulta } = this.props


        return (
            <React.Fragment>

                <Button label="Descargar tabla" icon="pi pi-download" className="p-button-success p-ml-2"
                    loading={this.state.generandoCsv} onClick={this.generarCSV} />

            </React.Fragment>
        )
    }


    actionBodyTemplate(rowData) {
        // console.log(this.props)
        const { devolverPostulationTable, seeFilesUnified, anularPostulationTable, bandejaAdmin } = this.props


        return (
            <React.Fragment>
                <div className="button-group-table">
                    <Button title='Ver anexos' className="button-table btn-see" onClick={() => seeFilesUnified(rowData)} >
                        <FileCopyIcon />
                    </Button>
                    {rowData.state === 4 || rowData.state === 5 ? "" :
                        <React.Fragment>
                            <Button title='Validar postulacion' className="button-table btn-edit" onClick={e => this.functionOpenForm(rowData)} >
                                <OpenInNewIcon />
                            </Button>
                            <React.Fragment>
                                {rowData.state === 3 ? "" :
                                    <Button title='Devolver' className="button-table btn-return" onClick={e => devolverPostulationTable(rowData)}>
                                        <AssignmentReturnIcon />
                                    </Button>
                                }
                            </React.Fragment>
                            <Button title='Anular' className="button-table btn-close" onClick={() => anularPostulationTable(rowData)}>
                                <CancelPresentationIcon />
                            </Button>
                        </React.Fragment>
                    }

                </div>
            </React.Fragment>
        );
    }


    render() {
        const {
            key,
            columns,
            dataPaginada,
            tituloTabla,
            modeSelection,
            className,

        } = this.props;

        const header = (
            <div className="table-header">
                <h5 className="p-m-0">{tituloTabla}</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Buscar empresas" />
                </span>
            </div>
        );

        const dynamicColumns = columns.map((col, i) => {
            return <Column key={col.field} field={col.field} header={col.header} />;
        });

        return (
            <div>
                <div className={`card ${className}`}>
                    {modeSelection === "clickFile" ? // recibe el modo de seleccion de un campo de la tabla, este es hacer clic sobre una fila de la tabla
                        <DataTable id="convsCerradasTable" columnResizeMode="fit" className="p-datatable-gridlines"
                            ref={(el) => this.dt = el}
                            globalFilter={this.state.globalFilter} loading={this.state.loading}
                            value={this.state.listaPaginada ? this.state.listaPaginada : dataPaginada ?
                                dataPaginada : this.state.convs}
                            selection={this.state.selectedFile} onSelectionChange={e => this.functionOpenForm(e)} selectionMode="single"
                            dataKey={key} header={header} emptyMessage="No hay registros">
                            {dynamicColumns}

                        </DataTable>
                        :
                        //Modo con botones de seleccion Ver y descargar
                        <DataTable id="convsCerradasTable" columnResizeMode="fit" className="p-datatable-gridlines"
                            ref={(el) => this.dt = el}
                            globalFilter={this.state.globalFilter} loading={this.state.loading}
                            value={this.state.listaPaginada ? this.state.listaPaginada : dataPaginada ?
                                dataPaginada : this.state.convs}
                            //selection={this.state.selectedFile} onSelectionChange={e => this.functionOpenForm(e)} selectionMode="single"
                            dataKey={key} header={header} emptyMessage="No hay registros">
                            {dynamicColumns}
                            <Column headerStyle={{ width: '200px' }} header="Acciones" body={this.actionBodyTemplate}></Column>
                        </DataTable>
                    }
                    <Paginator first={this.state.basicFirst} rows={this.state.basicRows} rowsPerPageOptions={[10, 20, 30, 50, 100]}
                        template="FirstPageLink PrevPageLink NextPageLink RowsPerPageDropdown CurrentPageReport"
                        currentPageReportTemplate="Página {currentPage} de la lista de conversaciones"
                        onPageChange={this.onBasicPageChange} totalRecords={1000000}>

                    </Paginator>
                </div>
                <Toolbar className="p-mb-4" right={this.rightToolbarTemplate}></Toolbar>

            </div>

        );
    }
}


