import shadows from "@material-ui/core/styles/shadows";
import { firebaseDatabase } from "./firebase";
import axios from "axios";
import { takeLatest, put, call, select, actionChannel } from "redux-saga/effects";
import { PLANTILLA_DE_LA_GENTE } from "./constants";

export const generateUUID = () => {
    var u = '', i = 0;
    var today = new Date();
    while (i++ < 36) {
        var c = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'[i - 1], r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        u += (c == '-' || c == '4') ? c : v.toString(16)
    }
    return u + "-" + today.getTime().toString();
}

export const getCatalog = async (id) => {
    const catalogRespose = firebaseDatabase.collection(`catalogos/${id}/data`);
    return catalogRespose;
};

export const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
}

export const exist = (array, value) => {
    var ban = false;
    array.forEach(element => {
        if (element.value === value) {
            ban = true;
            return;
        }
    });
    return ban;
}

export const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export const getAsyncCatalog = async (type) => {
    try {
        let catalogo = [];
        const catalogRespose = await firebaseDatabase.collection(`catalogos/${type}/data`);
        const collection = await catalogRespose.get();

        await collection.docs.map(doc => {
            catalogo.push(doc.data());
        });
        return catalogo;

    } catch (error) {
        throw ` utils.js - getAsyncCatalog - ${error}`;
    }

}

export const existLabel = (array, label) => {
    var ban = false;
    if (array.length > 0) {
        array.forEach(element => {
            if (element.label === label) {
                ban = true;
                return;
            }
        });
    }
    return ban;
}

export const getAsyncCollection = async (type) => {
    var collection = false;
    const catalogRespose = await firebaseDatabase.collection(`catalogos`).doc(`${type}`).get();
    collection = catalogRespose.data();
    return collection;
}

export const emptyObject = (value) => {
    return typeof value !== 'object' && value !== undefined ? value : '';
}

export const emptyVacio = (value) => {
    return typeof value !== 'object' && value !== undefined && value.length < 1 ? true : false;
}

export const emptyVacioDisabled = (value) => {
    return typeof value !== 'object' && value !== undefined && value.length > 0 ? true : false;
}

export const validOportunidad = (value, items) => {
    let valid = false;
    if (value.consultaOportunidadResponse && value.consultaOportunidadResponse.Opportunity && value.consultaOportunidadResponse.Opportunity.LifeCycleStatusCode === "5") {
        const oportunidad = value.consultaOportunidadResponse.Opportunity;
        const codPlan = items.homeType === "urbano" ? "Z02" : "Z03";
        if (oportunidad.SalesCycle.SalesCycleCode !== codPlan) {
            valid = true;
        }
    }
    return valid;
}

export const emptyJson = (value) => {
    return value !== undefined && JSON.stringify(value) === '{}' ? true : false;
}

export const emptyObjectEmp = (value, replace) => {
    return typeof value !== 'object' && value !== undefined ? value : replace;
}

export const emptyCero = (value) => {
    return value !== '' && value !== undefined && value !== null ? value : '0'
}

export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export const removeAccents = (str) => {
    const acentos = { 'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u', 'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U', '´': ' ', };

    return str.split('').map(letra => acentos[letra] || letra).join('').toString();
}

export const cortarMSM = (mens, limite) => {
    try {
        let newMsm = mens;
        if (mens && mens.length > limite) {
            newMsm = mens.substr(0, limite - 1)
        }
        return newMsm;
    } catch (error) {
        throw `cortarMSM - ${error}`

    }
}

export const letras_numbers = (str) => {
    //verifica si una caden esta compuesta solo por letras numeros y espacios
    var patt = new RegExp(/^[A-Za-z0-9\s]+$/g);
    return patt.test(str);
}

export const enviarEmail = (action) => {
    try {
        const { from, to, subject, message } = action
        const url = process.env.REACT_APP_URL_SEND_EMAIL_COMPANY;


        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            'Access-Control-Allow-Credentials': 'true',
            "Access-Control-Request-Headers": "access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,auth,authorization,cache-control,content-type,mode",
            'accept': "application/json, text/plain, */*",
            "Authorization": 'Basic Rk9STVVMQVJJT1NXRUI6WWsyMTkwX3c=',
            "content-type": "application/json;charset=iso-8859-1",
            "Cache-Control": "no-cache",
            'mode': "opaque ",
        };
        let body = {
            from,
            to,
            subject,
            message,
        }

        console.log(body, url)

        axios.create({
            headers,
        }).post(url, body)
            .then(response => {
                console.log(response.data)
            })
            .catch(err => {
                console.error('error invocando enviar email:' + err + " " + err.message)
                throw err;
            })

    } catch (error) {
        console.error("Function.enviarEmail", error);
    }
}

export const encode = (data) => {
    return Buffer.from(data, 'utf-8').toString('base64')
}

let sinDiacriticos = (function () {
    let de = 'ÁÃÀÄÂÉËÈÊÍÏÌÎÓÖÒÔÚÜÙÛÑÇáãàäâéëèêíïìîóöòôúüùûñç',
        a = 'AAAAAEEEEIIIIOOOOUUUUNCaaaaaeeeeiiiioooouuuunc',
        re = new RegExp('[' + de + ']', 'ug');

    return texto =>
        texto.replace(
            re,
            match => a.charAt(de.indexOf(match))
        );
})();

export const homologarParentezco = (catalog, payload) => {


    let res = "";
    const filtro = catalog.find(e => parseInt(e.value) === parseInt(payload));
    if (filtro) res = filtro.id_crm;


    return res;
};

export const conversionNumber = (value) => {
    var fin = value;
    if (value && value.length > 0 && value.includes("$")) {
        fin = ((value.replaceAll(",", "")).replaceAll(" ", "")).replaceAll("$", "");
    }
    return fin;
}

export const fontSizeCortar = (cadena) => {
    return cadena.length > 21 ? cadena.slice(0, 21) : cadena;
};

export const calculaCierre = (fina) => {
    return fina ? (0 + parseInt(emptyCero(fina?.totalahorroprevio)) + parseInt(emptyCero(fina?.totalrecursos)) + parseInt(emptyCero(fina?.valorsubcidiado))).toString() : "0";
};

export const dividePhone = (phone, clave) => {
    let response = ""
    const aux = phone.split("-");
    if (clave === "telefono1")
        response = aux[0];
    if (clave === "telefono2")
        if (aux.length > 1)
            response = aux[1];

    return response;
}

export const conversionSexo = (action) => {
    var res = { label: action, value: "" };
    if (action) {
        if (action === "M") {
            res.value = "2";
        }
        else if (action === "F") {
            res.value = "1";
        } else {
            res.value = "3";
        }
    }
    return res;
}

export const decode = (data) => {
    return Buffer.from(data, 'base64').toString('utf-8')
}

export const notificaFalloWBS = (action) => {
    try {
        const { body, webService, response } = action

        const from = "Notificaciones bandeja comercial";
        const to = `<jamu@comfenalcovalle.com.co>`;
        const subject = `${process.env.REACT_APP_TITLE_NOTIFICATIONS_EMAIL}`;
        const message = `<div><p>El web service <b>${webService}</b> presento una respuesta inesperada, a lo cual se requiere tomar control de la acción esperada</p><p>Cuerpo del webservice</p><p>${JSON.stringify(body)}</p><p>Respuesta del webservice</p><p>${JSON.stringify(response)}</p> </div>`
        const res = enviarEmail({ from, to, subject, message })

    } catch (error) {
        throw `saga.js - notificaFalloWBS ${error}`;
    }
}

export const cuantosSalarios = (salario, monto) => {
    return `${(parseInt(monto) / parseInt(salario)).toFixed(2)}`;
}

export function* consumeServiceGenerico(action) {
    try {
        const { body, url } = action;

        const request = { urlServicio: url, body: body }
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            'Access-Control-Allow-Credentials': 'true',
            "Access-Control-Request-Headers": "*",
            'accept': "application/json, text/plain, */*",
            "Authorization": 'Basic Rk9STVVMQVJJT1NXRUI6WWsyMTkwX3c=',
            "content-type": "application/json;charset=iso-8859-1",
            "Cache-Control": "no-cache",
            'mode': "opaque ",
        };

        const respues = yield call(axios.post, process.env.REACT_APP_FIREBASE_SEND_CONSUME_SERVICIOS_GENERICOS, request, headers);

        return respues;
    } catch (error) {
        console.error("Function.consumeServiceGenerico", error);
        throw `Function.consumeServiceGenerico - saga.js -${error}`;
    }
}

export function idDeparta(catalogo, id) {
    var idDepa = id
    catalogo.forEach(element => {
        if (element.value === id) {
            idDepa = element.id_depa;
        }
    });
    return idDepa;
}

export function crearCities(citys, idDepto) {
    var arrCitys = [];
    citys.forEach((element) => {
        if (element.id_depa === idDepto) {
            arrCitys.push({
                label: element.label,
                value: element.value,
            });
        }
    });
    return arrCitys;
}

export function getFechaOfDateV3() {

    let fecha = new Date();

    let dia = fecha.getDate();
    let mon = fecha.getMonth() + 1;
    if (fecha.getDate() < 10) {
        dia = `0${fecha.getDate()}`;
    }
    if (fecha.getMonth() < 10) {
        mon = `0${mon}`;
    }

    const hh = (fecha.getHours());
    const mmh = (fecha.getMinutes());

    let year = `${fecha.getFullYear()}`;

    return `${dia}-${mon}-${year.slice(2, 4)} ${hh}${mmh}`
}

export function getFechaStringOfDate(date) {

    let fecha = ""

    if (date && date.seconds) {
        fecha = date.toDate();
    } else {
        fecha = new Date(date);
    }

    let dia = fecha.getDate();
    let mon = fecha.getMonth() + 1;
    if (fecha.getDate() < 10) {
        dia = `0${fecha.getDate()}`;
    }
    if (fecha.getMonth() < 10) {
        mon = `0${mon}`;
    }

    let year = `${fecha.getFullYear()}`;

    return `${year}-${mon}-${dia}`
}


export const returnDataCatalogCities = (catalog, payload, param, campo) => {

    let res = "";
    if (catalog.length > 0 && payload && param && campo) {
        let newPayload = payload;
        if (payload.includes("-")) {
            const temp = payload.split("-");
            res = catalog.find(o => o[param].toUpperCase().includes(temp[0].toUpperCase()) && o[param].toUpperCase().includes(temp[1]));
        } else {
            res = catalog.find(o => o[param].toUpperCase() === newPayload.toUpperCase());
        }
        if (res)
            res = res[campo];

    }
    return res;
}

export const returnDataCatalog = (catalog, payload, param, campo) => {

    let res = "";
    if (catalog.length > 0 && payload && param && campo) {
        if (param === "value")
            res = catalog.find(o => parseInt(o[param]) === parseInt(payload));
        else
            res = catalog.find(o => o[param].toUpperCase() === payload.toUpperCase());
        // if (res && res !== undefined)
        //     res = res[campo];

    }
    return res && res !== undefined ? res[campo] : "";
}

export const filterCodeOportunidad = (data) => {
    let res = false;
    if (data) {
        res = {};
        res.inicio = data.ZboFormularioSubsidio.SAP_UUID;
        res.Autorizacion = data.ZboFormularioSubsidio.Autorizacion.SAP_UUID;
        res.DatoAfiliado = data.ZboFormularioSubsidio.DatoAfiliado.SAP_UUID;
        res.FinanciacionTotal = data.ZboFormularioSubsidio.FinanciacionTotal.SAP_UUID;
        res.HogarPostulante = data.ZboFormularioSubsidio.HogarPostulante.SAP_UUID;
        res.Importante = data.ZboFormularioSubsidio.Importante.SAP_UUID;
        res.InformacionPostulacion = data.ZboFormularioSubsidio.InformacionPostulacion.SAP_UUID;
        res.InformacionCuentas = data.ZboFormularioSubsidio.InformacionCuentas.SAP_UUID;
        res.ModalidadVivienda = data.ZboFormularioSubsidio.ModalidadVivienda.SAP_UUID;
        res.ModalidadViviendaV2 = data.ZboFormularioSubsidio.ModalidadViviendaV2.SAP_UUID;
        res.RecursoEconomico = data.ZboFormularioSubsidio.RecursoEconomico.SAP_UUID;
        res.TipoAfiliado = data.ZboFormularioSubsidio.TipoAfiliado.SAP_UUID;
        res.Totales = data.ZboFormularioSubsidio.Totales.SAP_UUID;
        res.ValorSolucionConstruccion = data.ZboFormularioSubsidio.ValorSolucionConstruccion.SAP_UUID;
    }
    return res;
}

export const convertStringToDate = (str) => {
    const aux = str.split(" ");
    const t1 = aux[0].split("/");
    const t2 = aux[1].split(":");
    return new Date(parseInt(t1[2]), parseInt(t1[1]) - 1, parseInt(t1[0]), parseInt(t2[0]), parseInt(t2[1]), parseInt(t2[2]));

}

export const saveHistoryViviendaActualizacion = async (action) => {
    const { idFormulario, history } = action;
    try {
        await firebaseDatabase.collection(process.env.REACT_APP_FIREBASE_COLECCION_ACTUALIZACION_VIVIENDA)
            .doc(idFormulario)
            .collection("history")
            .add(history);
    } catch (error) {
        console.error(error);
    }
}

export const retornaIdCatalog = (catalog, label) => {
    var ban = "";
    if (catalog && catalog.length > 0) {
        const res = catalog.find(e => e.label === label);
        if (res)
            ban = res.value;
    }
    return ban;
}

export const homologaSexo = (action) => {
    var res = ""
    if (action) {
        if (action === "1") {
            res = "2";
        }
        else if (action === "2") {
            res = "1";
        } else {
            res = "3";
        }
    }
    return res;
}

export const conversion = (value) => {
    var fin = "";
    if (value) {
        fin = value;
        var aux = value.split(" ");
        if (aux.length > 1) {
            fin = "";
            var tem = aux[1].split(",");
            tem.forEach(element => {
                fin = fin + element;
            });
        }
    }
    return fin;
}

export function* mayor(action) {
    try {
        const valor = (parseInt(action[0].safeValueA.value) + parseInt(action[1].safeValueA.value)).toString();
        var respuesta = {
            openDateA: action[0].openDateA.value,
            entityA: action[0].entityA.value,
            safeValueA: valor,
        }
        var fechauno = new Date(action[0].openDateA.value);
        var fechados = new Date(action[1].openDateA.value);
        var resultado = fechados.getTime() < fechauno.getTime();
        if (resultado) {
            respuesta.openDateA = action[1].openDateA.value;
        }
        if (parseInt(action[1].safeValueA.value) > parseInt(action[0].safeValueA.value)) {
            respuesta.entityA = action[1].entityA.value;
        }
        return respuesta;
    } catch (error) {
        console.error("function.mayor", error)
    }

}

export const primeraMayuscula = (cadena) => {
    return cadena[0].toUpperCase() + cadena.substring(1);
}

export const enviarEmailFormatDocument = (action) => {
    try {
        const { type, oportunidad, motivo, observacion, from, to, subject, numRegistro } = action


        switch (type) {
            case "DEVOLUCION":
                const devolucion = `<p>Apreciado usuario,&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                <p>Para nosotros es un placer saludarle en nombre de la Caja de Compensaci&oacute;n Familiar del Valle del Cauca, Comfenalco Valle Delagente.</p>
                <p>Le informamos que hemos realizado el proceso de verificaci&oacute;n para la afiliaci&oacute;n de su empresa, sin embargo, hemos evidenciado inconsistencias en los documentos recibidos, por lo tanto se realiza devoluci&oacute;n de la afiliaci&oacute;n por el siguiente motivo:</p>
                <p>${primeraMayuscula(motivo)}, ${observacion.toLowerCase()}</p>
                <p>Le invitamos a retomar la postulaci&oacute;n enviado la documentaci&oacute;n corregida por medio de nuestra <a href="https://afiliacionempresasprueba-f1bbf.web.app/empresas">Sucursal Virtual</a> con el mismo n&uacute;mero de registro: ${numRegistro} que ademas, se envi&oacute; al correo electr&oacute;nico registrado al iniciar su proceso de afiliaci&oacute;n. Recuerde que una vez enviados los documentos, la afiliaci&oacute;n pasa nuevamente a estar en proceso de validaci&oacute;n por parte del &aacute;rea correspondiente la cual le notificar&aacute; al correo electr&oacute;nico si la afiliaci&oacute;n es aprobada o no.</p>
                <p>Se aclara que hasta tanto no se reciba la documentaci&oacute;n faltante, Comfenalco Valle Delagente no podr&aacute; proceder a realizar la afiliaci&oacute;n de su empresa.</p>
                <p>Estamos siempre a su disposici&oacute;n para responder sus requerimientos a trav&eacute;s de nuestros canales de atenci&oacute;n. <a href="https://www.comfenalcovalle.com.co/personas/servicio-al-cliente/">Con&oacute;celos aqu&iacute;</a>.</p>
                <p>Gracias por confiar en nuestra Caja de Compensaci&oacute;n Familiar.</p>
                <p>&nbsp;</p>
                <p>Cordialmente,</p>`;
                enviarEmailGenerico({ from, to, subject, message: devolucion });
                break;
            case "ANULACION":
                const anulacion = `<p>Apreciado usuario,</p>
                <p>Para nosotros es un placer saludarle en nombre de la Caja de Compensaci&oacute;n Familiar del Valle del Cauca, Comfenalco Valle Delagente.</p>
                <p>Le informamos que hemos realizado el proceso de verificaci&oacute;n para la afiliaci&oacute;n de su empresa, sin embargo, hemos evidenciado inconsistencias en los documentos recibidos, por lo tanto se realizar la anulaci&oacute;n de la afiliaci&oacute;n por el siguiente motivo:</p>
                <p>${primeraMayuscula(motivo)}, ${observacion.toLowerCase()}</p>
                <p>Estamos siempre a su disposici&oacute;n para responder sus requerimientos a trav&eacute;s de nuestros canales de atenci&oacute;n. <a href="https://www.comfenalcovalle.com.co/personas/servicio-al-cliente/">Con&oacute;celos aqu&iacute;</a>.</p>
                <p>Gracias por confiar en nuestra Caja de Compensaci&oacute;n Familiar.</p>
                <p>Cordialmente,</p>`;
                enviarEmailGenerico({ from, to, subject, message: anulacion });
                break;
            case "OPORTUNIDAD":
                const oportunidadMen = `<p>Apreciado usuario,</p>
                <p>Para nosotros es un placer saludarle en nombre de la Caja de Compensaci&oacute;n Familiar del Valle del Cauca, Comfenalco Valle Delagente.</p>
                <p>Le informamos que el proceso de afiliaci&oacute;n de su empresa a la Caja de Compensaci&oacute;n Familiar fue registrado bajo el n&uacute;mero ${oportunidad}. En caso de presentar alguna novedad le ser&aacute; notificada por este medio en el cual se le indicar&aacute; informaci&oacute;n detallada y as&iacute;, podr&aacute; retomar la afiliaci&oacute;n a trav&eacute;s de nuestra <a href="https://afiliacionempresasprueba-f1bbf.web.app/empresas">Sucursal Virtual</a> con el mismo n&uacute;mero indicado.</p>
                <p>Estamos siempre a su disposici&oacute;n para responder sus requerimientos a trav&eacute;s de nuestros canales de atenci&oacute;n. <a href="https://www.comfenalcovalle.com.co/personas/servicio-al-cliente/">Con&oacute;celos aqu&iacute;</a>.</p>
                <p>Gracias por confiar en nuestra Caja de Compensaci&oacute;n Familiar.</p>
                <p>Cordialmente,</p>`;
                enviarEmailGenerico({ from, to, subject, message: oportunidadMen });
                break;

            default:
                break;
        }


    } catch (error) {
        console.error("Function.enviarEmailGenerico", error);
    }
}

export const enviarEmailGenerico = (action) => {
    try {
        const { from, to, subject, message } = action
        const url = process.env.REACT_APP_URL_SEND_EMAIL_COMPANY;


        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            'Access-Control-Allow-Credentials': 'true',
            "Access-Control-Allow-Headers": "x-requested-with, content-type",
            //"Access-Control-Request-Headers": "authorization,cache-control,content-type,mode",
            'accept': "application/json, text/plain, */*",
            "Authorization": 'Basic Rk9STVVMQVJJT1NXRUI6WWsyMTkwX3c=',
            "content-type": "application/json",
            "Cache-Control": "no-cache",
            'mode': "opaque ",
        };
        let body = {
            from,
            to,
            subject,
            message: message + PLANTILLA_DE_LA_GENTE,
            attachments: [],
        }

        axios.create({
            headers,
        }).post(url, JSON.stringify(body))
            .then(response => {
                console.log(response.data)
            })
            .catch(err => {
                console.error('error invocando enviar email:' + err + " " + err.message)
                throw err;
            }
            )


    } catch (error) {
        console.error("Function.enviarEmailGenerico", error);
    }
}

export const manejoDeexepciones = (err) => {

    let error = "Error: El servidor no está disponible en este momento."
    switch (true) {
        case (`${err} `).includes("Network Error"):
            error = "Error: No se pudo conectar al servidor. Verifique su conexión a Internet e intente nuevamente."
            break;
        case (`${err} `).includes("Not Found"):
            error = "Error: La página que está buscando no existe."
            break;
        case (`${err} `).includes("Internal Server Error"):
            error = "Error: Se produjo un error interno en el servidor."
            break;
        case (`${err} `).includes("Service Unavailable"):
            error = "Error: El servidor está temporalmente inactivo."
            break;
        case (`${err} `).includes("status code 500"):
            error = "Error: El servidor presenta un error interno. Está temporalmente inactivo."
            break;
        default:
            break;
    }
    return error;
}

export const getAsyncUserRolFirebase = async (email) => {
    try {
        return new Promise(async (resolve, reject) => {
            await firebaseDatabase.collection(`catalogos/USER_ROL_BANDEJA_VIVIENDA/data`)
                .where("email", "==", email).get().then((QuerySnapshot) => {
                    QuerySnapshot.docs.map((doc, i) => {
                        if (doc.exists) resolve(doc.data());
                    })
                }).catch((error) => {
                    console.error(error);
                    reject(error);
                });

        });
    } catch (error) {
        console.error("exception:", error);
        throw `utils.js - getAsyncUserRolFirebase - ${error}`;
    }
}

export const consultaMsgParametrizado = async (catalog, clave) => {
    try {
        const msgSession = catalog.find(e => e.value === clave);
        // if (!(msgSession && msgSession.download)) {
        //     return new Promise(async (resolve, reject) => {
        //         await firebaseDatabase.collection(`catalogos/MSG_PARAMETRIZADOS/data`).where('value', '==', clave)
        //             .get().then((QuerySnapshot) => {
        //                 QuerySnapshot.docs.map(doc => {
        //                     if (doc.exists)
        //                         resolve({ text: doc.data().label, download: true, id: clave });
        //                     else
        //                         resolve({ text: msgSession.label, download: false, id: clave })
        //                 });
        //             }).catch((err) => reject({ text: msgSession.label, download: false, id: clave }));
        //     });


        // }
        // else
        return { text: msgSession.label, download: msgSession.download, id: clave };
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const convertirFecha = (fecha) => {
    const fech = fecha.toDate();
    const mm = (fech.getMonth() + 1)
    const dd = (fech.getDate());
    const hh = (fech.getHours());
    const mmh = (fech.getMinutes());
    const ssh = (fech.getSeconds());
    return `${dd > 9 ? "" + dd : "0" + dd}/${mm > 9 ? "" + mm : "0" + mm}/${fech.getFullYear()} ${hh > 9 ? "" + hh : "0" + hh}:${mmh > 9 ? "" + mmh : "0" + mmh}:${ssh > 9 ? "" + ssh : "0" + ssh}`;
}

//compara si dos json y llena el primero con los datos del segundo que esten vacios
export const mergeJsonIfEmpty = (dataOld, dataNew) => {
    for (const key in dataOld) {
        if (dataOld.hasOwnProperty(key)) {
            // Si el valor es un objeto, hacer la comparación recursiva
            if (typeof dataOld[key] === "object" && dataOld[key] !== null && !Array.isArray(dataOld[key])) {
                mergeJsonIfEmpty(dataOld[key], dataNew[key] || {});
            } else {
                // Si el campo está vacío en dataOld, reemplazarlo con el valor de dataNew
                if (
                    dataOld[key] === null ||
                    dataOld[key] === undefined ||
                    dataOld[key] === ""
                ) {
                    dataOld[key] = dataNew[key];
                }
            }
        }
    }
    return dataOld;
}

export const ordenar = (a, b) => {
    if (a.label > b.label) {
        return 1;
    }
    if (a.label < b.label) {
        return -1;
    }
    return 0;
}