import React from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Button, ButtonGroup } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CustomForm from "../../components/materialform/customform";
import {
    ACTIONS_OF_APROVE,
    ANULACION_FORM,
    ANULACION_FORM_NAME,
    DEVOLUCIONES_FORM,
    DEVOLUCIONES_FORM_NAME,
    OCULTAR_DE_ANULACIONES,
    RETURN_HOME_BANDEJA,
    SEARCH_HISTORIAL_BANDEJA,
} from "./constants";
import {
    getDynamicOptions,
    getFormRevalidacion,
    getIdFormulario,
    getNameButtonDev,
    getOcultaDevoluciones,
} from "./selectors";

function Componet({ stepForm, setStepForm, stepFinal, setChangeStep }) {
    const dispatch = useDispatch();
    const openDevolucionesForm = (value) => dispatch({ type: OCULTAR_DE_ANULACIONES, value });
    const searchHistorial = (value) => dispatch({ type: SEARCH_HISTORIAL_BANDEJA, value });
    const actionsButtonsAprove = (value) => dispatch({ type: ACTIONS_OF_APROVE, value });
    const returnToHome = (value) => dispatch({ type: RETURN_HOME_BANDEJA, value });

    const ocultaDevoluciones = useSelector(getOcultaDevoluciones);
    const idFormulario = useSelector(getIdFormulario);
    const nameButtonDev = useSelector(getNameButtonDev);
    const dynamicOptions = useSelector(getDynamicOptions);
    const formRevalidacion = useSelector(getFormRevalidacion);

    return (
        <React.Fragment>
            {!formRevalidacion ?
                <div className="devoluciones-container">
                    <Button
                        color={"secondary"}
                        type={"submit"}
                        className={"buttonSeeDevoluciones"}
                        onClick={() => openDevolucionesForm(true)}
                        startIcon={ocultaDevoluciones ? <ExpandMoreIcon /> : false}
                    >
                        <h3 className="title-devoluciones">Devoluciones</h3>
                    </Button>
                    {!ocultaDevoluciones ?
                        <React.Fragment>
                            <CustomForm
                                submitable={false}
                                items={DEVOLUCIONES_FORM}
                                formName={DEVOLUCIONES_FORM_NAME}
                                className="people-form"
                                dynamicOptions={dynamicOptions}
                            />
                        </React.Fragment>
                        : ""}
                </div>
                : ""}
            <div className="anulaciones-container">
                <React.Fragment>
                    <CustomForm
                        submitable={false}
                        items={ANULACION_FORM}
                        formName={ANULACION_FORM_NAME}
                        className="people-form"
                        dynamicOptions={dynamicOptions}
                    />
                </React.Fragment>
            </div>
            <div className="buttons-container">
                <div className="navega-buttons">
                    <ButtonGroup color="primary" aria-label="primary button group">
                        {stepForm > 0 ?
                            <Button
                                onClick={() => {

                                    setChangeStep(true)
                                    setStepForm(stepForm - 1)
                                }}
                            >
                                Atras
                            </Button>
                            : ""}

                        <Button onClick={() => searchHistorial(idFormulario)}>
                            Historial
                        </Button>

                    </ButtonGroup>
                </div>
                <div className="button-wrapper">
                    <ButtonGroup color="primary" aria-label="primary button group">

                        <Button
                            id={"devolve-anule"}
                            onClick={() => actionsButtonsAprove({ type: nameButtonDev, from: "revision" })}
                        >
                            {formRevalidacion ? "Anular" : nameButtonDev}
                        </Button>

                        {stepFinal && stepFinal === stepForm ?
                            <Button
                                id={"aprove-button"}
                                onClick={() => actionsButtonsAprove({ type: "AFILIAR", from: "revision" })}
                            >
                                Afiliar
                            </Button>
                            :
                            ""}
                        {stepFinal && stepFinal === stepForm ? "" :
                            <Button onClick={() => {
                                setChangeStep(true)
                                setStepForm(stepForm + 1)
                            }}>
                                Continuar
                            </Button>
                        }
                        <Button onClick={() => returnToHome()}>
                            Salir
                        </Button>
                    </ButtonGroup>

                </div>
            </div>
        </React.Fragment>
    );
}

export const ComponetComercial = connect(null, null)(Componet);
