export const REDUX_CHANGE = '@@redux-form/CHANGE';
export const REDUX_BLUR = '@@redux-form/BLUR';

export const VALIDATE_USER_TYPE = '@bandejaVivienda/validateUserType';
export const SAVED_INFO_USER_BANDEJA = '@bandejaVivienda/savedInfoUserBandeja';
export const SET_DATA_PAGINAR_REG_VIVIENDA = '@bandejaVivienda/setDataPaginarRegVivienda';
export const PAGINAR_DATA_REG_VIVIENDA = '@bandejaVivienda/paginarDataRegVivienda';
export const SEARCH_FORM_FROM_TABLE = '@bandejaVivienda/searchFormFromTable';
export const ERROR_GENERAL = '@bandejaVivienda/errorGeneral';
export const OPEN_SEE_FILES_UNIFIED = '@bandejaVivienda/openSeeFilesUnified';
export const DEVOLVER_POSTULATION_FROM_TABLE = '@bandejaVivienda/devolverPostulationFromTable';
export const ANULAR_POSTULATION_FROM_TABLE = '@bandejaVivienda/anularPostulationFromTable';
export const MESSAGE_ALERT_SEND = '@bandejaVivienda/messageAlertSend';
export const RETURN_HOME_BANDEJA = '@bandejaVivienda/returnHomeBandeja';
export const GESTIONA_MODAL_TRY_CATCH = '@bandejaVivienda/gestionAModalTryCatch';

export const CLOSE_MODALES = '@bandejaVivienda/closeModales';
export const OPEN_FORM_BANDEJA = '@bandejaVivienda/openFormBandeja';

export const DATA_POR_VALIDAR = "No se encuentran solicitudes pendientes de validación para su usuario";
export const TITLE_TABLE_PAGINATOR_EMPRESAS = 'Actualización Postulaciones a subsidio de vivienda';

export const VISUALIZE_ANEXOS = '@bandejaVivienda/visualizeAnexos';
export const CLOSE_MODAL_ANEXOS = '@bandejaVivienda/closeModalAnexos';
export const OPEN_MODAL_CHARGED = '@bandejaVivienda/openModalCharged';
export const OCULTAR_DEVOLUCIONES = '@bandejaVivienda/ocultarDevoluciones';
export const OCULTAR_DE_ANULACIONES = '@bandejaVivienda/ocultarDeAnulaciones';
export const SEARCH_HISTORIAL_BANDEJA = '@bandejaVivienda/searchHistorialBandeja';
export const OPEN_HISTORIAL_BANDEJA = '@bandejaVivienda/openHistoryBandeja';
export const ACTIONS_OF_APROVE = '@bandejaVivienda/actionsButtonsAprove';
export const MESSAGE_CONFIRMATION_SEND = '@bandejaVivienda/messageConfirmationSend'
export const DEVOLUCIONES_FORM_NAME = 'devolucionForm';
export const ANULACION_FORM_NAME = 'anulacionForm';
export const LOAD_DOCUMENT_SUCCESS = '@bandejaVivienda/loadDocumentSuccess';
export const ANULACION_FORM_TITLE = 'Anulaciones';
export const OPEN_ANULATION_FORM = '@bandejaVivienda/openAnulationForm';
export const TYPE_USER_BANDEJA_VIVIENDA = '@bandejaVivienda/typeUserBandejaVivienda';
export const OPEN_ANEXOS_FROM_TABLE = '@bandejaVivienda/openAnexosFromTable';
export const VISUALIZE_ANEXOS_FROM_TABLE = '@bandejaVivienda/visualizeAnexosFromTable';
export const OPEN_MODAL_ANULATION_TABLE = '@bandejaVivienda/openModalAnulationTable';
export const OPEN_MODAL_DEVOLUTION_TABLE = '@bandejaVivienda/openModalDevolutionTable';
export const SAVED_ACTIONS_VALIDATION = '@bandejaVivienda/savedActivations;';
export const SAVE_DATA_TABLE_ANULA_DEVOL = '@bandejaVivienda/saveDataTableAnulaDevol';
export const LOAD_CATALOG_BANDEJA = '@bandejaVivienda/loadCatalogBandeja';
export const INIT_FORM_FROM_REVALIDACION = '@bandejaVivienda/initFormFromRevalidacion';
export const INIT_BANDEJA_ADMINISTRADOR = '@bandejaVivienda/initBandejaAdministrador';


export const HEADERS_WS = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
    'Access-Control-Allow-Credentials': 'true',
    "Access-Control-Request-Headers": "access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,auth,authorization,cache-control,content-type,mode",
    'accept': "application/json, text/plain, */*",
    "Authorization": 'Basic Rk9STVVMQVJJT1NXRUI6WWsyMTkwX3c=',
    "content-type": "application/json",
    "Cache-Control": "no-cache",
    'mode': "opaque ",
}

export const MESSAGES_ALERT_PARAMETRIZADOS = [
    { value: "msgRegiDownloadNotBD", download: false, label: "La afiliación que intenta descargar no se encontro en base de datos, por favor inténtelo nuevamente. En caso de presentarse este mensaje una vez más por favor comuníquese con nuestra línea de Servicio al Cliente 602 8862727 opción 2" },
    { value: "msgNotPermitAccessUser", download: false, label: "El usuario no cuenta con los permisos necesarios para acceder a la informacion de la bandeja." },
    { value: "msgDevolCmrSuccess", download: false, label: "El proceso de devolución a comercial ha terminado exitosamente." },
    { value: "msgDevolCmrFailure", download: false, label: "No se pudo realizar el proceso de devolución a comercial correctamente. Si el problema persiste comuniquese con el area de soporte ComfenalcoValle delagente." },
    { value: "msgAfiliaSuccess", download: false, label: "El proceso de afiliación ha terminado exitosamente, la actualización fue afiliada con al siguiente informacion:" },
    { value: "msgAfiliaFailure", download: false, label: "No se pudo realizar el proceso de afiliación correctamente. Si el problema persiste comuniquese con el area de soporte ComfenalcoValle delagente." },
    { value: "msgDevolSuccess", download: false, label: "El proceso de devolución ha terminado exitosamente." },
    { value: "msgDevolFailure", download: false, label: "No se pudo realizar el proceso de devolucion correctamente. Si el problema persiste comuniquese con el area de soporte ComfenalcoValle delagente." },
    { value: "msgAnulaSuccess", download: false, label: "El proceso de anulación ha terminado exitosamente." },
    { value: "msgAnulaFailure", download: false, label: "No se pudo realizar el proceso de anulación correctamente. Si el problema persiste comuniquese con el area de soporte ComfenalcoValle delagente" },
    { value: "msgAprobacionSuccess", download: false, label: "El proceso de aprobación ha terminado exitosamente." },
    { value: "msgAprobacionFailure", download: false, label: "No se pudo realizar el proceso de aprobación correctamente. Si el problema persiste comuniquese con el area de soporte ComfenalcoValle delagente" },
]

export const HEADER_TABLE_PAGINATOR_EMPRESAS = [
    { field: "dateUpdate", header: "Fecha actualización", sortable: true },
    { field: "oportunidad", header: "N° Oportunidad", sortable: true },
    { field: "idUser", header: "Identificación", sortable: true },
    { field: "nameUser", header: "Nombres", sortable: true },
    { field: "cellPhone", header: "Teléfono", sortable: true },
    { field: "dateManage", header: "Fecha gestión", sortable: true },
    { field: "asesor", header: "Responsable", sortable: true },
    { field: "stateLabel", header: "Estado", sortable: true }
];

export const CATALOG_STATES = [{ "label": "Validación", "value": "2" }, { "label": "Anulada", "value": "4" }, { "label": "Unificacion", "value": "1" }, { "label": "Afiliada", "value": "9" }, { "value": "5", "label": "Devuelta" }, { "label": "Borrador", "value": "0" }, { "value": "3", "label": "Re-validación" }]

export const DEVOLUCIONES_FORM = [

    {
        label: "",
        name: "datosdevolucion",
        type: "section",
        typeForm: "section",
        className: "rs-100",
        fields: [
            {
                //tooltip: "Seleccione el motivo de la anualación",
                label: "Motivo devolución",
                name: "motivoDevolucion",
                placeholder: "Motivo devolución",
                type: "autocomplete",
                typeForm: "autocomplete",
                value: false,
                options: [],
                disabled: false,
                className: "rs-40"
            },
            {
                //tooltip: "Ingresa la observacion pertinente",
                label: "Observación",
                name: "observation",
                placeholder: "Observación",
                type: "text",
                typeForm: "text",
                maxLength: 2000,
                value: false,
                options: [],
                disabled: false,
                className: "rs-60",
                hidden: false
            },

        ]
    }
]

export const ANULACION_FORM = [
    {
        label: ANULACION_FORM_TITLE,
        name: "datosanulacion",
        type: "section",
        typeForm: "section",
        className: "rs-100",
        fields: [
            {
                //tooltip: "Seleccione el motivo específico de la anualación",
                label: "Motivo anulación",
                name: "motivoAnulacion",
                placeholder: "Motivo anulación",
                type: "autocomplete",
                typeForm: "autocomplete",
                value: false,
                options: [],
                disabled: false,
                className: "rs-40"
            },
            {
                //tooltip: "Ingresa la observacion pertinente",
                label: "Observación",
                name: "observationAnulation",
                placeholder: "Observación",
                type: "text",
                typeForm: "text",
                maxLength: 2000,
                value: false,
                options: [],
                disabled: false,
                className: "rs-60",
                hidden: false
            },

        ]
    }
]
